<template>
  <div class="marshall-footer">
    <el-row>
      <el-col :span="8">
        <div class="btn" v-on:click="$emit('getTeeTimes', pickedDate)">
          Refresh
        </div>
      </el-col>
      <el-col :span="8">
        <div
          class="btn"
          style="border-left: 1px solid lightgrey"
          v-on:click="onShowDate"
        >
          Datum
        </div>
      </el-col>
      <el-col :span="8">
        <div
          class="btn"
          style="border-left: 1px solid lightgrey"
          v-on:click="$emit('onLogout')"
        >
          Afmelden
        </div>
      </el-col>
    </el-row>

    <el-dialog
      :append-to-body="false"
      :visible.sync="dialogVisible"
      destroy-on-close
      style="z-index: 2002"
      title="Kies een datum"
      width="320px"
    >
      <el-date-picker
        v-model="pickedDate"
        format="dd-MM-yyyy"
        type="date"
        value-format="yyyy-MM-dd"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Annuleer</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      pickedDate: "",
    };
  },
  watch: {
    pickedDate() {
      this.$emit("onSetDate", this.pickedDate);
      this.dialogVisible = false;
    },
  },
  mounted() {
    this.pickedDate = this.$dayjs().format("YYYY-MM-DD");
  },
  methods: {
    onShowDate() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss">
.btn {
  background-color: #12263f;
  text-align: center;
  color: white;
  cursor: pointer;
  width: 100%;
  height: 50px;
  padding-top: 10px;
}

.marshall-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
}
</style>
