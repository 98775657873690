<template>
  <div class="tee-time-row" style="display: flex">
    <div style="width: 100px; float: left">
      {{ time }}
    </div>

    <div style="width: 50px; float: left; text-align: left">
      {{ holes }}
    </div>

    <div
      class="hide-on-md"
      style="min-width: 50px; float: left; text-align: left"
    >
      {{ this.teeTime.atHole }}
    </div>

    <div
      class="hide-on-md"
      style="min-width: 120px; width: 10%; float: left"
      v-html="course"
    />

    <div style="flex-grow: 1" v-html="playerNames" />

    <div style="min-width: 50px; width: 10%; float: left">
      {{ handicap }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teeTime: Object,
  },
  computed: {
    time() {
      return this.teeTime.timeFrom.slice(0, -3);
    },
    playerNames() {
      let result = "";

      if (this.teeTime.sttDescr) {
        return (
          "tot - " +
          this.teeTime.timeTo.slice(0, -3) +
          ": " +
          this.teeTime.sttDescr
        );
      }

      for (let i = 1; i <= 4; i++) {
        if (this.teeTime["sttPlayerName" + i].trim().length > 0) {
          let color = this.teeTime["sttGrpColor" + i]
            ? this.teeTime["sttGrpColor" + i]
            : "black";

          let checkInColor =
            this.teeTime["checkin" + i] === 1 ? "darkgreen" : color;

          result +=
            "<span style='border-bottom: 2px solid " +
            checkInColor +
            "; line-height: 26px; ; padding-left:2px; padding-right:2px; margin-right:2px; background-color:" +
            color +
            "'>" +
            this.teeTime["sttPlayerName" + i] +
            "</span>";
        }
      }

      return result;
    },
    handicap() {
      if (this.teeTime.totalHandicap === undefined) {
        return "";
      }
      return Math.round(this.teeTime.totalHandicap);
    },
    course() {
      let result =
        " <span style='font-weight:bold;'>" + this.teeTime.crlChar + "</span>";
      if (this.teeTime.crlChar2 !== null) {
        result += " / " + this.teeTime.crlChar2;
      }
      if (this.teeTime.crlChar3 !== null) {
        result =
          this.teeTime.crlChar3 +
          " / " +
          " <span style='font-weight:bold;'>" +
          this.teeTime.crlChar +
          "</span>";
      }
      return result;
    },
    holes() {
      return this.teeTime.holes;
    },
  },
};
</script>

<style>
.tee-time-row {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dashed lightgrey;
}

@media (max-width: 992px) {
  .hide-on-md {
    display: none;
  }
}
</style>
